<template>
  <v-container
      id="login"
      class="fill-height justify-center"
      tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>
    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center" v-show="!globalLoading">
      <v-slide-y-transition appear>
        <base-material-card
            color="success"
            light
            max-width="100%"
            width="400"
            class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Login
              </h1>
            </div>
          </template>
          <form @submit="checkForm" novalidate="true">
            <v-card-text class="text-center">

              <v-text-field
                  color="secondary"
                  label="Email..."
                  prepend-icon="mdi-email"
                  v-model="username"
              />

              <v-text-field
                  type="password"
                  class="mb-8"
                  color="secondary"
                  label="Password..."
                  prepend-icon="mdi-lock-outline"
                  v-model="password"
              />

              <pages-btn
                  medium
                  color=""
                  depressed
                  class="mr-1 mb-2 v-btn--text success--text"
                  @click="checkForm"
              >
                <v-icon class="mr-1">mdi-fingerprint</v-icon>
                Log In
              </pages-btn>
              <pages-btn
                  medium
                  color=""
                  depressed
                  class="mb-2 v-btn--text info--text"
                  @click="$router.push('forgot-password')"
              >
                <v-icon class="mr-1">mdi-lock</v-icon>
                Forgot Password?
              </pages-btn>

            </v-card-text>
          </form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";
import externalMixins from "./mixins/index";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PagesLogin',

  mixins: [globalMixins, externalMixins],

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    username: '',
    password: '',
  }),

  async beforeCreate() {
    let project = this.$router.currentRoute.query.project;
    if (project && project !== '') {
      this.$store.dispatch('project/set', project);
    }
  },

  async created() {
    this.globalLoading = true;
    if (this.isAuthenticated) {
      if (!this.currentUser) {
        // if this page is requested and the user is already logged in but their info is missing, fetch the info then redirect to main dashboard
        return await this.fetchUserAndAcl()
            .then(() => {
              return this.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
            });
      } else {
        // if this page is requested and the user is already logged in, redirect to main dashboard
        return this.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
      }
    }

    this.globalLoading = false;
  },

  methods: {
    ...mapActions('auth', ['setToken', 'setUserAndACL']),

    async checkForm(e) {
      if (!this.username || !this.validUsernameOrEmail(this.username)) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a valid username or email address.'
        });
        return false;
      }

      if (!this.password) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a password.'
        });
        return false;
      }

      await this.submit();
      e.preventDefault();
    },

    forgotPasswordClicked(e) {
      e.preventDefault();
      this.$router.push('forgot-password');
    },

    /* eslint-disable */
    validUsernameOrEmail: function (email) {
      var re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])|([A-Za-z]+[A-Za-z0-9_-]{2,19}))$/;
      return re.test(email);
    },

    /* eslint-enable */
    async submit() {
      const self = this;
      this.clearMessages();
      return self.executeRequest({
        url: '/token',
        method: 'post',
        headers: {
          env: 'portal'
        },
        params: {
          username: self.username,
          password: self.password
        }
      }).then(async response => {
        if (response.redirect) {
          self.$router.push({path: response.redirect});
        } else {
          self.setToken(response.token);
          self.fetchUserAndAcl()
              .then(() => {
                self.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
              });
        }

      }).catch(error => {
        self.handleErrorResponse(error);
      });
    },

  },
  computed: {
    ...mapGetters({user: 'auth/getUser', token: 'auth/getToken'}),
  }
}
</script>
